<template>
  <div class="mainContainer container" style="">


    <div style="display:flex;">
      <back-button />
      <h5 class="pageTitle">Test</h5>
    </div>
    <hr>

    <div class="contentBody">


    </div>
  </div>
</template>

<script>
import { datetime, RRule, RRuleSet, rruleDb } from 'rrule'
var d = new Date();

export default {
  name: 'Test2',
  components: {

  },

  data(){
    return{
      pageMode:'upcoming',
      isAdmin:false,
      trans:[],
      familyUsers:[],
      activeTranId:null,
      showTranDetailsModal:false,
      showDeleteModal:false,
      shortLength:120,
      allLoaded:false,
      batchSize:10,
      batch:0,
      days:365,
      schedules:[],
      allUpcomingTrans:[],
      resSumByBas:[],
      resSumByUsers:[],
    }
  },

  computed:{
    activeTran(){
      return this.activeTranId ? this.trans.filter(t=>t.ID === this.activeTranId)[0] : {};
    }
  },


  methods:{

    getKidsTrans: function(){
      let loader = this.$loading.show();
      this.axios
          .post(this.$hostApi,{task:'getKidsTrans',type:this.pageMode},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(results =>{
            //console.log(schedules.data);
            this.schedules = results.data;
            let schedule;
            for(schedule of this.schedules){
              console.log('------------------------------new schedule '+schedule.ID+' starts');
              console.log(schedule);
              if(schedule.SCHEDULE){
                var rruleDb = JSON.parse(schedule.SCHEDULE);
                console.log('rruleDb is ');
                console.log(rruleDb);
                const rule = new RRule(this.createRruleOpt(rruleDb));

// Get all occurrence dates (Date instances):
                rule.all();


                var trans = rule.all().map(function(date)
                  {
                    return {'date':date.toLocaleDateString('en-CA', {timeZone: 'UTC'}), 'sId':schedule.ID, 'baId':schedule.ACCOUNT_ID, 'userId':schedule.USER_ID, 'amount':schedule.AMOUNT, 'tranDesc':schedule.DETAILS}
                  }
                );

                console.log(trans);

// Get a slice:
                //rule.between(datetime(2012, 8, 1), datetime(2012, 9, 1));
                //console.log(rule.between(datetime(2012, 8, 1), datetime(2012, 9, 1)));

// Get an iCalendar RRULE string representation:
// The output can be used with RRule.fromString().

                rule.toString();
                console.log(rule.toString());
// Get a human-friendly text representation:
// The output can be used with RRule.fromText().

                rule.toText();
                console.log(rule.toText());


                this.allUpcomingTrans.push(...trans);
              }


            }


            this.allUpcomingTrans.sort((a,b)=>a.date.localeCompare(b.date));
            console.log(this.allUpcomingTrans);

            this.sumByBas('2024-01-01');
            this.sumByUsers();

            loader.hide();
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },




    createRruleOpt(rruleDb){


      var rruleObj = {};

      rruleObj.freq = RRule[rruleDb.freq];
      rruleObj.interval = rruleDb.interval;
      rruleObj.dtstart = new Date(rruleDb.dtstart);


      if(rruleDb.freq === 'WEEKLY'){
        let weekday;
        rruleObj.byweekday = [];
        for(weekday of rruleDb.byweekday){
          rruleObj.byweekday.push(RRule[weekday]);
        }

      }

      if(rruleDb.freq === 'MONTHLY' || rruleDb.freq === 'YEARLY'){
        if(rruleDb.bymonthday){
          rruleObj.bymonthday = rruleDb.bymonthday
        }

        if(rruleDb.byweekday){
          rruleObj.byweekday = RRule[rruleDb.byweekday];
          rruleObj.bysetpos = rruleDb.bysetpos;
        }

        if(rruleDb.bysetpos){
          rruleObj.bysetpos = rruleDb.bysetpos;
        }
      }



      if(rruleDb.freq === 'YEARLY'){
        //console.log(this.bymonth);
        rruleObj.bymonth = rruleDb.bymonth;

      }

      if(rruleDb.freq === 'never'){
        rruleObj.freq = RRule.DAILY;
        rruleObj.count = 1;
      }



      if(rruleDb.endOption && rruleDb.endOption === 'until'){
        rruleObj.until = new Date(rruleDb.endDate);
      }

      if(rruleDb.endOption && rruleDb.endOption === 'count'){
        rruleObj.count = rruleDb.count;
      }else{
        if(rruleDb.count){
          rruleObj.count = rruleDb.count;
        }else{
          rruleObj.count = 2000;
        }
      }
      console.log('rruleObj is');
      console.log(rruleObj);

      return rruleObj;

    },

    sumByBas(d){
      var trans = this.allUpcomingTrans.filter(tran=>tran.date <= d);
      var resSumByBas = [];
      trans.reduce(function(res, tran) {
        if (!res[tran.baId]) {
          res[tran.baId] = {baId: tran.baId, userId:tran.userId, amount: 0};
          resSumByBas.push(res[tran.baId]);
        }
        res[tran.baId].amount += parseFloat(tran.amount);
        return res;
      }, {});

      this.resSumByBas = resSumByBas;

      console.log(trans);
      console.log(this.resSumByBas);
    },

    sumByUsers(){
      var resSumByUsers = [];
      this.resSumByBas.reduce(function(res, ba) {
        if (!res[ba.userId]) {
          res[ba.userId] = {userId: ba.userId, amount: 0};
          resSumByUsers.push(res[ba.userId])
        }
        res[ba.userId].amount += parseFloat(ba.amount);
        return res;
      }, {});

      this.resSumByUsers = resSumByUsers;

      console.log(this.resSumByUsers);
    },


  },
  created() {
  },
  mounted() {
    this.getKidsTrans();
  },

}
</script>

<style scoped>


.mainContainer{
  margin-top:150px;
}

.logo{
  width:40px;
  height:40px;
  padding:3px;
  border-radius: 50%;
  border:1px solid #0dcaf0;
  filter: invert(68%) sepia(64%) saturate(2311%) hue-rotate(145deg) brightness(97%) contrast(95%);
  margin-bottom:10px;
}

.info{
  font-size:20px;
  text-align: center;

}


#transDiv{
  margin-top:30px;
}

.tran{
  height:110px;
  box-shadow: 1px 0 2px 0 rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 19%);
  margin:15px 0;
  border-radius: 3px;
  background-color: #f6f6c6;
}

.tranDate{
  text-align: center;
  font-size:12px;
  background-color: #a4a415;
  color:#ffffff;
  padding:5px;
}

.sharedIcon{
  float:right;
  margin-right:10px;
}

.tranBottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:10px;
}





.leftDiv{
  display: flex;
  justify-content: left;
  align-items: center;
}

.avatar{
  margin-left:5px;
  width:65px;
}

.avatar img{
  width:60px;
  height:60px;
  border:2px solid #9c9c9c;
  border-radius: 50%;
  padding:3px;
}

.desc{
  margin-left:20px;
  font-size:11px;
  width:calc(100% - 165px);
}



.amount{
  font-size:18px;
  width:100px;
  text-align: right;
}

.currencyCode{
  font-size:9px;
  text-align: right;
}






.gotoBtnDiv{
  background-color: #b7fa8b;
}





.lightBulb{
  color:orange;
  margin-right:10px;
}

.shareDiv{
  margin-top:20px;
  background-color: #b7fa8b;
  display: flex;
  justify-content: left;
  align-items: center;
  padding:10px;
  border-radius: 3px;

}

.shareIconDiv{
  padding:0 20px 0 10px;
  font-size:20px;
  color:orange;
}

.delYes {
  background-color: red;
  color: #fff;
}

.delConfirmBtn {
  text-align: center;
  border: 1px solid #f5f5f5;
  margin: 10px;
}

.delNo {
  background-color: #e4e4e4;
  color: #3b3b3b;
}

.loadMore{
  text-align: center;
  width:200px;
  margin:20px auto;
}




/*modal*/
.modalParent :deep(.modal-container) {
  width:100%;
  top:20px;

}
.modalParent :deep(.modal-content){
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #f6f6c6;
  width:unset;

}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 14px;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0 solid #e2e8f0;
}

.tranDetailsModal .modal-header{
  display: none;
}


.btn.closeModal {
  background-color: #e3e1e1;
  border: 0;
  padding:5px 10px;
  margin-top:20px;
}

/*end of modal */


</style>
